import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import PropTypes from 'prop-types'
import { formatOtpVerifyMethods } from '../GettingStarted/utils'

const VerifcationMethod = ({ inputs, otpMethod, handleSelectOtpMethod }) => {
    const otpMethods = formatOtpVerifyMethods(inputs)

    const {
        methodDiv,
        methodDivActive,
        methodIcon,
        methodText,
        customStrokeAndFillStyle,
        customStrokeAndFillStyleIcon,
    } = styles
    return (
        <>
            {otpMethods?.map((each, i) => (
                <div
                    key={i}
                    role="button"
                    className={`${methodDiv} ${
                        otpMethod === each?.key ? methodDivActive : ''
                    } ${each?.key === 'phone' ? customStrokeAndFillStyle : ''}`}
                    onClick={() => handleSelectOtpMethod(each?.key)}
                >
                    <div
                        className={`${methodIcon} ${
                            each?.key === 'phone'
                                ? customStrokeAndFillStyleIcon
                                : ''
                        }`}
                    >
                        {each?.icon}
                    </div>
                    <div className={methodText}>
                        <h4>{each?.title}</h4>
                        <p>{each?.description}</p>
                    </div>
                </div>
            ))}
        </>
    )
}

VerifcationMethod.propTypes = {
    inputs: PropTypes.object,
    handleSelectOtpMethod: PropTypes.func,
    otpMethod: PropTypes.string,
}

export default VerifcationMethod
