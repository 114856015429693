import { InputFields, SelectField } from '../InputFields'
import { Upload } from 'antd'
import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import styles from './asionboarding.module.scss'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { stateData } from 'src/utils/stateData'
import { isMobile } from 'src/utils/mediaQueries'

const ASIOnboarding = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    setErrors,
    successUpload,
    setSuccessUpload,
}) => {
    const { Dragger } = Upload
    const [uploading, setUploading] = useState(false)

    const props = {
        name: 'file',

        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
            setErrors(prev => ({
                ...prev,
                resume: '',
            }))
        },
        onDrop() {
            setUploading(false)
        },
    }

    return (
        <>
            <div>
                <div className={styles.NamesContainer}>
                    <InputFields
                        name="first_name"
                        title="First Name"
                        handleChange={handleChange}
                        value={inputs.first_name}
                        paddingRight={isMobile ? 0 : 5}
                        errorMessage={errors?.first_name}
                    />
                    <InputFields
                        name="last_name"
                        title="Last Name"
                        handleChange={handleChange}
                        value={inputs.last_name}
                        paddingRight={isMobile ? 0 : 5}
                        errorMessage={errors?.last_name}
                    />
                </div>
                <SelectField
                    initialOption="Location"
                    selectWidth="100%"
                    marginBottom="20px"
                    values={stateData.map(option => ({
                        value: option,
                    }))}
                    name="state"
                    showSearch
                    currentSelected={inputs.state}
                    selectedValue={inputs.state}
                    dropdownPositionRelative
                    handleChange={handleSelectChange}
                    errorMessage={errors?.state}
                />
                <InputFields
                    title="Years of Solar Installation Experience"
                    value={inputs.years_of_experience}
                    handleChange={handleChange}
                    name="years_of_experience"
                    type="number"
                    errorMessage={errors?.years_of_experience}
                />

                <div>
                    <p className={styles.UploadCV}>Upload your C.V.</p>

                    <Dragger
                        fileList={successUpload}
                        className="DraggerArea"
                        maxCount={1}
                        showUploadList={true}
                        accept=".pdf,.xls,.xlsx"
                        customRequest={() => {}}
                        {...props}
                    >
                        <p className="ant-upload-drag-icon">
                            {uploading ? (
                                <div className="SlideContainer">
                                    <p
                                        className="Slider"
                                        style={{
                                            width: `40%`,
                                        }}
                                    ></p>
                                </div>
                            ) : successUpload.length > 0 ? (
                                <img
                                    src={SuccessUpload}
                                    className="UploadLogo"
                                    alt="success logo"
                                />
                            ) : (
                                <img
                                    src={UploadLogo}
                                    className="UploadLogo"
                                    alt="upload logo"
                                />
                            )}
                        </p>
                        <p className="UploadText">
                            {uploading ? (
                                <div>Uploading document...</div>
                            ) : successUpload.length > 0 ? (
                                <>
                                    <p className="Upload">
                                        <u
                                            className="Browse"
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        >
                                            Replace document
                                        </u>
                                    </p>
                                </>
                            ) : (
                                <>
                                    Upload file. Drag and drop or
                                    <u className="Browse">browse</u>
                                </>
                            )}
                        </p>
                    </Dragger>
                    <p className="ErrorText">{errors?.resume}</p>
                </div>
            </div>
        </>
    )
}

ASIOnboarding.propTypes = {
    inputs: PropTypes.object,
    handleChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
}

export default ASIOnboarding
